import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import GetDashboardUseCase from "../../../domain/use-cases/dashboards/GetDashboardUseCase"
import UpdateDashboardUseCase from "../../../domain/use-cases/dashboards/UpdateDashboardUseCase"
import DashboardError from "../../../../../core/domain/entities/dashboards/DashboardError"
import DashboardErrorsObject from "../../../../../core/domain/entities/dashboards/DashboardErrorsObject"
import Dashboard from "../../../../../core/domain/entities/dashboards/Dashboard"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import { v4 as uuidv4 } from "uuid"
import Block from "../../../../../core/domain/entities/blocks/Block"
import BlockErrorsObject from "../../../../../core/domain/entities/blocks/BlockErrorsObject"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import Image from "../../../../../core/domain/entities/images/Image"
import Banner from "../../../../../core/domain/entities/banners/Banner"
import BannerErrorsObject from "../../../../../core/domain/entities/banners/BannerErrorsObject"
import LinkType from "../../../../../core/domain/entities/links/LinkType"
import CreateBannerImageUseCase from "../../../domain/use-cases/banner-images/CreateBannerImageUseCase"
import BlockColumnsCount from "../../../../../core/domain/entities/blocks/BlockColumnsCount"
import BlockProductCategory from "../../../../../core/domain/entities/block-product-categories/BlockProductCategory"
import BlockProductCategoryErrorsObject
  from "../../../../../core/domain/entities/block-product-categories/BlockProductCategoryErrorsObject"
import ProductCategory from "../../../../../core/domain/entities/product-categories/ProductCategory"
import ImageFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ImageFormField"
import HeaderContentType from "../../../../../core/domain/entities/header/HeaderContentType"
import Header from "../../../../../core/domain/entities/header/Header"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import assertNever from "../../../../../../sqadmin/lib/assertNever"
import ProductsSet from "../../../../../core/domain/entities/products-sets/ProductsSet"
import ProductsSetCondition from "../../../../../core/domain/entities/products-set-condition/ProductsSetCondition"
import ProductsSetErrorsObject from "../../../../../core/domain/entities/products-sets/ProductsSetErrorsObject"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import BannerDataType from "../../../../../core/domain/entities/banners/BannerDataType"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BlockType from "../../../../../core/domain/entities/blocks/BlockType"
import BannersBlockViewType from "../../../../../core/domain/entities/blocks/BannersBlockViewType"
import ProductsBlockViewType from "../../../../../core/domain/entities/blocks/ProductsBlockViewType"
import ProductCategoriesBlockViewType from "../../../../../core/domain/entities/blocks/ProductCategoriesBlockViewType"
import FormFieldPlaceType
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/FormFieldPlaceType"
import CreateDashboardUseCase from "../../../domain/use-cases/dashboards/CreateDashboardUseCase"
import DestroyDashboardUseCase from "../../../domain/use-cases/dashboards/DestroyDashboardUseCase"
import GetDashboardsUseCase from "../../../domain/use-cases/dashboards/GetDashboardsUseCase"
import MenuItem from "../../../../../core/domain/entities/menu-items/MenuItem"
import MenuItemErrorsObject from "../../../../../core/domain/entities/menu-items/MenuItemErrorsObject"
import MenuItemType from "../../../../../core/domain/entities/menu-items/MenuItemType"
import { ProductsSetConditionsFields } from "../../../../../core/presentation/fields/ProductsSetConditionsFields"
import GetPropertyValuesUseCase from "../../../../property-values-core/domain/use-cases/GetPropertyValuesUseCase"
import GetPropertiesUseCase from "../../../../properties-core/domain/use-cases/GetPropertiesUseCase"
import GetProductCategoriesUseCase
  from "../../../../product-categories-core/domain/use-cases/product-categories/GetProductCategoriesUseCase"
import GetProductsUseCase from "../../../../products-core/domain/use-cases/products/GetProductsUseCase"

export default class DashboardViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getDashboardUseCase: GetDashboardUseCase
  private readonly getDashboardsUseCase: GetDashboardsUseCase
  private readonly updateDashboardUseCase: UpdateDashboardUseCase
  private readonly createDashboardUseCase: CreateDashboardUseCase
  private readonly destroyDashboardUseCase: DestroyDashboardUseCase
  private readonly createBannerImageUseCase: CreateBannerImageUseCase
  private readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
  private readonly getProductsUseCase: GetProductsUseCase
  private readonly getPropertiesUseCase: GetPropertiesUseCase
  private readonly getPropertyValuesUseCase: GetPropertyValuesUseCase
  private readonly dashboardId?: number
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly blockByImages: Map<string, string> = new Map()

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    Dashboard,
    DashboardError,
    DashboardErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getDashboardUseCase: GetDashboardUseCase
    readonly getDashboardsUseCase: GetDashboardsUseCase
    readonly updateDashboardUseCase: UpdateDashboardUseCase
    readonly destroyDashboardUseCase: DestroyDashboardUseCase
    readonly createDashboardUseCase: CreateDashboardUseCase
    readonly createBannerImageUseCase: CreateBannerImageUseCase
    readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
    readonly getProductsUseCase: GetProductsUseCase
    readonly getPropertiesUseCase: GetPropertiesUseCase
    readonly getPropertyValuesUseCase: GetPropertyValuesUseCase
    readonly dashboardId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getDashboardUseCase = parameters.getDashboardUseCase
    this.getDashboardsUseCase = parameters.getDashboardsUseCase
    this.updateDashboardUseCase = parameters.updateDashboardUseCase
    this.createDashboardUseCase = parameters.createDashboardUseCase
    this.destroyDashboardUseCase = parameters.destroyDashboardUseCase
    this.createBannerImageUseCase = parameters.createBannerImageUseCase
    this.getProductCategoriesUseCase = parameters.getProductCategoriesUseCase
    this.getProductsUseCase = parameters.getProductsUseCase
    this.getPropertiesUseCase = parameters.getPropertiesUseCase
    this.getPropertyValuesUseCase = parameters.getPropertyValuesUseCase
    this.dashboardId = parameters.dashboardId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    Dashboard,
    DashboardError,
    DashboardErrorsObject
  > {
    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.dashboardId),
      buildObject: async() => (this.createDashboard()),
      loadObject: async() => {
        return await this.getDashboardUseCase.call({
          dashboardId: this.dashboardId!
        })
      },
      updateObject: async({ object: dashboard }) => {
        return await this.updateDashboardUseCase.call({
          dashboardId: this.dashboardId!,
          dashboard
        })
      },
      createObject: async({ object: dashboard }) => {
        return await this.createDashboardUseCase.call({ dashboard })
      },
      getObjectUrl: (dashboard) => {
        return this.coreUrlProvider.buildDashboardUrl({
          id: dashboard.id!
        })
      },
      destroyObject: async() => {
        return await this.destroyDashboardUseCase.call({ dashboardId: this.dashboardId! })
      },
      onObjectChanged: (dashboard: Dashboard) => {
        this.blockByImages.clear()
        dashboard.blocks?.filter((block: Block) => isPresent(block.bannersViewType))
          ?.forEach((block: Block) => {
            block.banners?.forEach((banner: Banner) => {
              const clientId = banner.image?.clientId
              if (isPresent(clientId)) this.blockByImages.set(clientId, block!.bannersViewType!.valueOf())
            })
          })
      },
      getErrorsObject: ({ error: dashboardError }) => dashboardError?.errorsObject,
      formFields: [
        this.createNameFormField(),
        this.createBlocksFormField()
      ]
    })
  }

  private createNameFormField() {
    return new StringFormField<Dashboard, DashboardErrorsObject>({
      getTitle: () => this.coreI18n.getTextProvider().name(),
      getValue: (dashboard: Dashboard) => dashboard.name,
      setValue: (dashboard: Dashboard, name: string): Dashboard => {
        return { ...dashboard, name }
      },
      getErrors: (dashboardErrorsObject?: DashboardErrorsObject) => dashboardErrorsObject?.attributes?.name
    })
  }

  private createBlocksFormField() {
    return new ListFormField<Dashboard, DashboardErrorsObject, Block, BlockErrorsObject>({
      isEditVisible: true,
      getTitle: () => this.coreI18n.getTextProvider().blocks(),
      getValue: (dashboard: Dashboard) => dashboard.blocks,
      getErrors: (dashboardErrorsObject?: DashboardErrorsObject) => dashboardErrorsObject?.attributes?.blocks,
      setValue: (dashboard: Dashboard, blocks: Block[] | null): Dashboard => {
        return { ...dashboard, blocks }
      },
      getNestedObjectId: (block: Block) => block.clientId!,
      getNestedObjectTitle: (_: Block, index: number) => {
        return this.coreI18n.getTextProvider().blockWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (block: Block, dashboardErrorsObject?: DashboardErrorsObject) => {
        return dashboardErrorsObject?.blocks?.find((blockErrorsObject: BlockErrorsObject): boolean => {
          return blockErrorsObject.clientId === block.clientId
        })
      },
      hasNestedErrorsObjects: (dashboardErrorsObject?: DashboardErrorsObject) => {
        return isPresent(dashboardErrorsObject?.blocks)
      },
      getPosition: (block: Block) => block.position!,
      setPosition: (block: Block, position: number): Block => {
        return { ...block, position }
      },
      isSwitchPositionVisible: true,
      buildNewValue: () => ({
        clientId: uuidv4(),
        id: undefined,
        position: undefined,
        name: undefined,
        type: undefined,
        bannersViewType: undefined,
        productsViewType: undefined,
        productCategoriesViewType: undefined,
        banners: undefined,
        columnsCount: undefined,
        blockProductCategories: undefined,
        productsSet: undefined,
        header: undefined,
        menuItems: undefined
      }),
      fields: [
        this.createPreviewFormField(),
        this.createBlockTypeFormField(),
        this.createBannersBlockViewTypeFormField(),
        this.createProductsBlockViewTypeFormField(),
        this.createProductCategoriesBlockViewTypeFormField(),
        this.createBlockNameFormField(),
        this.createBlockHeaderLeftContentTypeFormField(),
        this.createBlockHeaderRightContentTypeFormField(),
        this.createBlockHeaderCenterContentTypeFormField(),
        this.createBlockColumnsCountFormField(),
        this.createBlockBannersFormField(),
        this.createBlockProductCategoriesFormField(),
        this.createBlockProductsSetConditionsFormField(),
        this.createBlockMenuFormField()
      ]
    })
  }

  private createPreviewFormField() {
    return new StringFormField<Block, BlockErrorsObject>({
      getPlaceType: () => FormFieldPlaceType.MAIN,
      getDisabled: () => true,
      getValue: (block: Block) => [block.type ? this.detectBlockTypeDisplayName(block.type) : "", block.name]
        .filter(value => isPresent(value))
        .join(", "),
      setValue: (block: Block) => ({ ...block }),
      getErrors: () => undefined
    })
  }

  private createBlockTypeFormField() {
    return new SingleSelectFormField<Block, BlockErrorsObject, BlockType>({
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(BlockType),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().blockType(),
      getValue: (block: Block) => block.type,
      setValue: (block: Block, blockType: BlockType | null): Block => {
        return this.setBlockTypeToBlock({ block, blockType })
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.type,
      getOptionId: (blockType: BlockType) => blockType.valueOf(),
      getOptionText: (blockType: BlockType) => this.detectBlockTypeDisplayName(blockType)
    })
  }

  private createBannersBlockViewTypeFormField() {
    return new SingleSelectFormField<Block, BlockErrorsObject, BannersBlockViewType>({
      isSearchBarVisible: false,
      getVisible: (block: Block) => block.type === BlockType.BANNERS,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(BannersBlockViewType),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().blockViewType(),
      getValue: (block: Block) => block.bannersViewType,
      setValue: (block: Block, blockViewType: BannersBlockViewType | null): Block => {
        return {
          ...block,
          bannersViewType: blockViewType
        }
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.type,
      getOptionId: (blockViewType: BannersBlockViewType) => blockViewType.valueOf(),
      getOptionText: (blockViewType: BannersBlockViewType) => this.detectBannersBlockViewTypeDisplayName(blockViewType)
    })
  }

  private createProductsBlockViewTypeFormField() {
    return new SingleSelectFormField<Block, BlockErrorsObject, ProductsBlockViewType>({
      isSearchBarVisible: false,
      getVisible: (block: Block) => block.type === BlockType.PRODUCTS,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(ProductsBlockViewType),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().blockViewType(),
      getValue: (block: Block) => block.productsViewType,
      setValue: (block: Block, blockViewType: ProductsBlockViewType | null): Block => {
        return {
          ...block,
          productsViewType: blockViewType
        }
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.type,
      getOptionId: (blockViewType: ProductsBlockViewType) => blockViewType.valueOf(),
      getOptionText: (blockViewType: ProductsBlockViewType) => {
        return this.detectProductsBlockViewTypeDisplayName(blockViewType)
      }
    })
  }

  private createProductCategoriesBlockViewTypeFormField() {
    return new SingleSelectFormField<Block, BlockErrorsObject, ProductCategoriesBlockViewType>({
      isSearchBarVisible: false,
      getVisible: (block: Block) => block.type === BlockType.PRODUCT_CATEGORIES,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(ProductCategoriesBlockViewType),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().blockViewType(),
      getValue: (block: Block) => block.productCategoriesViewType,
      setValue: (block: Block, blockViewType: ProductCategoriesBlockViewType | null): Block => {
        return {
          ...block,
          productCategoriesViewType: blockViewType
        }
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.type,
      getOptionId: (blockViewType: ProductCategoriesBlockViewType) => blockViewType.valueOf(),
      getOptionText: (blockViewType: ProductCategoriesBlockViewType) => {
        return this.detectProductCategoriesBlockViewTypeDisplayName(blockViewType)
      }
    })
  }

  private createBlockNameFormField() {
    return new StringFormField<Block, BlockErrorsObject>({
      getVisible: (block: Block) => this.isBlockNameVisible(block.type),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().title(),
      getValue: (block: Block) => block.name,
      setValue: (block: Block, name: string): Block => {
        return { ...block, name }
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.name
    })
  }

  private createBlockHeaderLeftContentTypeFormField() {
    return new SingleSelectFormField<Block, BlockErrorsObject, HeaderContentType>({
      isSearchBarVisible: false,
      getVisible: (block: Block) => this.isHeaderVisible(block.type),
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(HeaderContentType),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().blockHeaderLeftContent(),
      getValue: (block: Block) => block.header?.leftContentType,
      setValue: (block: Block, type: HeaderContentType | null | undefined): Block => {
        return {
          ...block,
          header: {
            ...block.header ?? this.createHeader(),
            leftContentType: type
          }
        }
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.header?.attributes?.leftContentType,
      getOptionId: (type: HeaderContentType) => type.valueOf(),
      getOptionText: (type: HeaderContentType) => this.detectHeaderContentTypeDisplayName(type)
    })
  }

  private createBlockHeaderCenterContentTypeFormField() {
    return new SingleSelectFormField<Block, BlockErrorsObject, HeaderContentType>({
      isSearchBarVisible: false,
      getVisible: (block: Block) => this.isHeaderVisible(block.type),
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(HeaderContentType),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().blockHeaderCenterContent(),
      getValue: (block: Block) => block.header?.centerContentType,
      setValue: (block: Block, type: HeaderContentType | null | undefined): Block => {
        return {
          ...block,
          header: {
            ...block.header ?? this.createHeader(),
            centerContentType: type
          }
        }
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.header?.attributes?.centerContentType,
      getOptionId: (type: HeaderContentType) => type.valueOf(),
      getOptionText: (type: HeaderContentType) => this.detectHeaderContentTypeDisplayName(type)
    })
  }

  private createBlockHeaderRightContentTypeFormField() {
    return new SingleSelectFormField<Block, BlockErrorsObject, HeaderContentType>({
      isSearchBarVisible: false,
      getVisible: (block: Block) => this.isHeaderVisible(block.type),
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(HeaderContentType),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().blockHeaderRightContent(),
      getValue: (block: Block) => block.header?.rightContentType,
      setValue: (block: Block, type: HeaderContentType | null | undefined): Block => {
        return {
          ...block,
          header: {
            ...block.header ?? this.createHeader(),
            rightContentType: type
          }
        }
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.header?.attributes?.rightContentType,
      getOptionId: (type: HeaderContentType) => type.valueOf(),
      getOptionText: (type: HeaderContentType) => this.detectHeaderContentTypeDisplayName(type)
    })
  }

  private createBlockColumnsCountFormField() {
    return new SingleSelectFormField<Block, BlockErrorsObject, BlockColumnsCount>({
      getVisible: (block: Block) => this.isSelectColumnsCountVisible(block),
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(BlockColumnsCount),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().blockColumnsCount(),
      getValue: (block: Block) => block.columnsCount,
      setValue: (block: Block, columnsCount: BlockColumnsCount | null): Block => {
        return { ...block, columnsCount }
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.columnsCount,
      getOptionId: (columnsCount: BlockColumnsCount) => columnsCount.valueOf(),
      getOptionText: (columnsCount: BlockColumnsCount) => columnsCount.valueOf()
    })
  }

  private createBlockBannersFormField() {
    return new ListFormField<Block, BlockErrorsObject, Banner, BannerErrorsObject>({
      getVisible: (block: Block) => this.isAddBannerImageVisible(block.type),
      isEditVisible: true,
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().banners(),
      getValue: (block: Block) => block.banners,
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.banners,
      setValue: (block: Block, banners: Banner[] | null): Block => {
        return { ...block, banners }
      },
      getNestedObjectId: (banner: Banner) => banner.clientId!,
      getNestedObjectTitle: (_: Banner, index: number) => {
        return this.coreI18n.getTextProvider().bannerWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (banner: Banner, blockErrorsObject?: BlockErrorsObject) => {
        return blockErrorsObject?.banners?.find((bannerErrorsObject: BannerErrorsObject): boolean => {
          return bannerErrorsObject.clientId === banner.clientId
        })
      },
      hasNestedErrorsObjects: (blockErrorsObject?: BlockErrorsObject) => {
        return isPresent(blockErrorsObject?.banners)
      },
      getPosition: (banner: Banner) => banner.position!,
      setPosition: (banner: Banner, position: number): Banner => {
        return { ...banner, position }
      },
      isSwitchPositionVisible: true,
      buildNewValue: () => ({
        clientId: uuidv4(),
        id: undefined,
        imageId: undefined,
        position: undefined,
        image: undefined,
        link: undefined,
        productsSet: undefined,
        dataType: undefined,
        dashboard: undefined,
        dashboardId: undefined
      }),
      fields: [
        this.createBlockBannerImageFormField(),
        this.createBlockBannerDataTypeFormField(),
        this.createBlockBannerLinkUrlFormField(),
        this.createBlockBannerDashboardFormField(),
        this.createBlockBannerProductsSetConditionsFormField()
      ]
    })
  }

  private createBlockBannerImageFormField() {
    return new ImageFormField<Banner, BannerErrorsObject, Image>({
      getPlaceType: () => FormFieldPlaceType.MAIN,
      getValue: (banner: Banner) => banner.image,
      setValue: (banner: Banner, image: Image | null | undefined): Banner => {
        return { ...banner, image, imageId: image && image.id }
      },
      getErrors: (bannerErrorsObject?: BannerErrorsObject) => bannerErrorsObject?.attributes?.imageId,
      getOptions: (image: Image) => {
        const imageVariant = this.detectImageVariantByBannerViewType(image)
        return [
          {
            url: imageVariant?.url,
            width: imageVariant?.dimensions?.width,
            height: imageVariant?.dimensions?.height,
            /*когда появится от сервера превью заменить*/
            originalSizeUrl: imageVariant?.url,
            originalWidth: imageVariant?.dimensions?.width,
            originalHeight: imageVariant?.dimensions?.height,
            name: undefined
          }
        ]
      },
      createObject: async({ file }) => {
        return await this.createBannerImageUseCase.call({ file })
      }
    })
  }

  private createBlockBannerDataTypeFormField() {
    return new SingleSelectFormField<Banner, BannerErrorsObject, BannerDataType>({
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(BannerDataType),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().dataType(),
      getValue: (banner: Banner) => banner.dataType,
      setValue: (banner: Banner, type: BannerDataType | null | undefined): Banner => {
        return {
          ...banner,
          dataType: type,
          link: undefined,
          productsSet: undefined,
          dashboard: undefined,
          dashboardId: undefined
        }
      },
      getErrors: (bannerErrorsObject?: BannerErrorsObject) => bannerErrorsObject?.attributes?.dataType,
      getOptionId: (type: BannerDataType) => type.valueOf(),
      getOptionText: (type: BannerDataType) => this.detectBannerDataTypeDisplayName(type)
    })
  }

  private createBlockBannerLinkUrlFormField() {
    return new StringFormField<Banner, BannerErrorsObject>({
      getVisible: (banner: Banner) => banner.dataType === BannerDataType.LINK,
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().url(),
      getValue: (banner: Banner) => banner.link?.url,
      setValue: (banner: Banner, url: string): Banner => {
        return {
          ...banner,
          link: isBlank(url) ? null : {
            ...banner.link,
            url,
            type: LinkType.BROWSER_LINK
          }
        }
      },
      getErrors: (bannerErrorsObject?: BannerErrorsObject) => bannerErrorsObject?.attributes?.link
    })
  }

  private createBlockBannerDashboardFormField() {
    return new SingleSelectFormField<Banner, BannerErrorsObject, Dashboard>({
      getVisible: (banner: Banner) => banner.dataType === BannerDataType.DASHBOARD,
      getObjects: async({
        query,
        lastObject
      }) => {
        return await this.getDashboardsUseCase.call({
          filter: { query },
          pagination: { id: lastObject?.id ?? undefined }
        })
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().dashboard(),
      getValue: (banner: Banner) => banner.dashboard,
      setValue: (banner: Banner, dashboard: Dashboard | null): Banner => {
        return {
          ...banner,
          dashboard: dashboard,
          dashboardId: dashboard && dashboard.id
        }
      },
      getErrors: (bannerErrorsObject?: BannerErrorsObject) => bannerErrorsObject?.attributes?.dashboard,
      getOptionId: (dashboard: Dashboard) => dashboard.id!.toString(),
      getOptionText: (dashboard: Dashboard) => dashboard.name
    })
  }

  private createBlockBannerProductsSetConditionsFormField() {
    return new ListFormField<Banner, BannerErrorsObject, ProductsSetCondition, ProductsSetErrorsObject>({
      getVisible: (banner: Banner) => banner.dataType === BannerDataType.PRODUCTS_SET,
      addObjectButtonName: this.coreI18n.getTextProvider().add(),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().productsSetConditions(),
      getValue: (banner: Banner): ProductsSetCondition[] | null | undefined => {
        return banner.productsSet && banner.productsSet.conditions
      },
      getErrors: (bannerErrorsObject?: BannerErrorsObject) => bannerErrorsObject?.attributes?.productsSet,
      setValue: (banner: Banner, productsSetConditions: ProductsSetCondition[] | null): Banner => {
        return {
          ...banner,
          productsSet: {
            ...banner.productsSet ?? this.createProductsSet(),
            conditions: productsSetConditions
          }
        }
      },
      getNestedObjectId: (productsSetCondition: ProductsSetCondition) => productsSetCondition.clientId!,
      getNestedObjectTitle: (_: ProductsSetCondition, index: number) => {
        return this.coreI18n.getTextProvider().productsSetConditionWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (_: ProductsSetCondition, bannerErrorsObject?: BannerErrorsObject) => {
        return bannerErrorsObject?.productsSet
      },
      hasNestedErrorsObjects: (bannerErrorsObject?: BannerErrorsObject) => isPresent(bannerErrorsObject?.productsSet),
      buildNewValue: (): ProductsSetCondition => this.createProductsSetCondition(),
      fields: this.createProductsSetConditionsFields().createFields()
    })
  }

  private createProductsSetConditionsFields() {
    return new ProductsSetConditionsFields({
      coreI18n: this.coreI18n,
      getProductCategories: async({ query, lastObjectId }) => await this.getProductCategoriesUseCase.call({
        filter: { query },
        pagination: { id: lastObjectId }
      }),
      getProperties: async({ query, lastObjectId }) => await this.getPropertiesUseCase.call({
        filter: { query },
        pagination: { id: lastObjectId }
      }),
      getPropertyValues: async({ query, propertyId }) => await this.getPropertyValuesUseCase.call({
        query,
        propertyId
      }),
      getProducts: async({ filter, query, lastObjectId }) => await this.getProductsUseCase.call({
        filter,
        query,
        pagination: { id: lastObjectId }
      }),
      findProductsSetForCondition: ({
        productsSetCondition
      }) => this.findProductsSetForCondition({ productsSetCondition })
    })
  }

  private createBlockProductCategoriesFormField() {
    return new ListFormField<Block, BlockErrorsObject, BlockProductCategory, BlockProductCategoryErrorsObject>({
      getVisible: (block: Block) => this.isAddBlockCategoriesVisible(block.type),
      addObjectButtonName: this.coreI18n.getTextProvider().add(),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().productCategories(),
      getValue: (block: Block) => block.blockProductCategories,
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.blockProductCategories,
      setValue: (block: Block, blockProductCategories: BlockProductCategory[] | null): Block => {
        return { ...block, blockProductCategories }
      },
      getNestedObjectId: (blockProductCategory: BlockProductCategory) => blockProductCategory.clientId!,
      getNestedObjectTitle: (_: BlockProductCategory, index: number) => {
        return this.coreI18n.getTextProvider().productCategoryWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (
        blockProductCategory: BlockProductCategory,
        blockErrorsObject?: BlockErrorsObject
      ) => {
        return blockErrorsObject?.blockProductCategories
          ?.find((blockProductCategoryErrorsObject: BlockProductCategoryErrorsObject): boolean => {
            return blockProductCategoryErrorsObject.clientId === blockProductCategory.clientId
          })
      },
      hasNestedErrorsObjects: (blockErrorsObject?: BlockErrorsObject) => {
        return isPresent(blockErrorsObject?.blockProductCategories)
      },
      getPosition: (blockProductCategory: BlockProductCategory) => blockProductCategory.position!,
      setPosition: (blockProductCategory: BlockProductCategory, position: number): BlockProductCategory => {
        return { ...blockProductCategory, position }
      },
      isSwitchPositionVisible: true,
      buildNewValue: () => ({
        clientId: uuidv4(),
        productCategory: undefined,
        productCategoryId: undefined,
        position: undefined
      }),
      fields: [
        this.createBlockProductCategoryFormField()
      ]
    })
  }

  private createBlockProductCategoryFormField() {
    return new SingleSelectFormField<BlockProductCategory, BlockProductCategoryErrorsObject, ProductCategory>({
      getObjects: async({
        query,
        lastObject
      }) => {
        return await this.getProductCategoriesUseCase.call({
          filter: { query },
          pagination: { id: lastObject?.id ?? undefined }
        })
      },
      getValue: (blockProductCategory: BlockProductCategory) => blockProductCategory.productCategory,
      setValue: (
        blockProductCategory: BlockProductCategory,
        productCategory: ProductCategory | null
      ): BlockProductCategory => {
        return { ...blockProductCategory, productCategory, productCategoryId: productCategory && productCategory.id }
      },
      getErrors: (blockProductCategoryErrorsObject?: BlockProductCategoryErrorsObject) => {
        return blockProductCategoryErrorsObject?.attributes?.productCategoryId
      },
      getOptionId: (category: ProductCategory) => category.id!.toString(),
      getOptionText: (category: ProductCategory) => category.name
    })
  }

  private createBlockProductsSetConditionsFormField() {
    return new ListFormField<Block, BlockErrorsObject, ProductsSetCondition, ProductsSetErrorsObject>({
      getVisible: (block: Block) => this.isProductsSetVisible(block.type),
      addObjectButtonName: this.coreI18n.getTextProvider().add(),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().productsSetConditions(),
      getValue: (block: Block): ProductsSetCondition[] | null | undefined => {
        return block.productsSet && block.productsSet.conditions
      },
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.productsSet,
      setValue: (block: Block, productsSetConditions: ProductsSetCondition[] | null): Block => {
        return {
          ...block,
          productsSet: {
            ...block.productsSet ?? this.createProductsSet(),
            conditions: productsSetConditions
          }
        }
      },
      getNestedObjectId: (productsSetCondition: ProductsSetCondition) => productsSetCondition.clientId!,
      getNestedObjectTitle: (_: ProductsSetCondition, index: number) => {
        return this.coreI18n.getTextProvider().productsSetConditionWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (_: ProductsSetCondition, blockErrorsObject?: BlockErrorsObject) => {
        return blockErrorsObject?.productsSet
      },
      hasNestedErrorsObjects: (blockErrorsObject?: BlockErrorsObject) => {
        return isPresent(blockErrorsObject?.productsSet)
      },
      buildNewValue: (): ProductsSetCondition => this.createProductsSetCondition(),
      fields: this.createProductsSetConditionsFields().createFields()
    })
  }

  private createBlockMenuFormField() {
    return new ListFormField<Block, BlockErrorsObject, MenuItem, MenuItemErrorsObject>({
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getVisible: (block: Block) => this.isMenuVisible(block.type),
      getTitle: () => this.coreI18n.getTextProvider().blockMenu(),
      getValue: (block: Block) => block.menuItems,
      isEditVisible: true,
      getErrors: (blockErrorsObject?: BlockErrorsObject) => blockErrorsObject?.attributes?.menuItems,
      setValue: (block: Block, menuItems: MenuItem[] | null): Block => {
        return { ...block, menuItems }
      },
      getNestedObjectId: (menuItem: MenuItem) => menuItem.clientId!,
      getNestedObjectTitle: (_: MenuItem, index: number) => {
        return this.coreI18n.getTextProvider().menuItemWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (menuItem: MenuItem, blockErrorsObject?: BlockErrorsObject) => {
        return blockErrorsObject?.menuItems?.find((menuItemErrorsObject: MenuItemErrorsObject): boolean => {
          return menuItem.clientId === menuItemErrorsObject.clientId
        })
      },
      hasNestedErrorsObjects: (blockErrorsObject?: BlockErrorsObject) => {
        return isPresent(blockErrorsObject?.menuItems)
      },
      getPosition: (menuItem: MenuItem) => menuItem.position!,
      setPosition: (menuItem: MenuItem, position: number): MenuItem => {
        return { ...menuItem, position }
      },
      isSwitchPositionVisible: true,
      buildNewValue: (): MenuItem => ({
        clientId: uuidv4(),
        id: undefined,
        title: undefined,
        position: undefined,
        type: undefined,
        link: undefined,
        dashboardId: undefined,
        dashboard: undefined,
        productsSet: undefined
      }),
      fields: [
        this.createBlockMenuItemName(),
        this.createBlockMenuItemType(),
        this.createBlockMenuItemLinkUrlFormField(),
        this.createBlockMenuItemDashboardFormField(),
        this.createBlockMenuItemProductsSetConditionsFormField()
      ]
    })
  }

  private createBlockMenuItemName() {
    return new StringFormField<MenuItem, MenuItemErrorsObject>({
      getPlaceType: () => FormFieldPlaceType.MAIN,
      getTitle: () => this.coreI18n.getTextProvider().name(),
      getValue: (menuItem: MenuItem) => menuItem.title,
      setValue: (menuItem: MenuItem, title: string): MenuItem => {
        return { ...menuItem, title: title }
      },
      getErrors: (menuItemErrorsObject?: MenuItemErrorsObject) => menuItemErrorsObject?.attributes?.title
    })
  }

  private createBlockMenuItemType() {
    return new SingleSelectFormField<MenuItem, MenuItemErrorsObject, MenuItemType>({
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: Object.values(MenuItemType),
            page: { hasMore: false }
          }
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      isSearchBarVisible: false,
      getTitle: () => this.coreI18n.getTextProvider().dataType(),
      getValue: (menuItem: MenuItem) => menuItem.type,
      setValue: (
        menuItem: MenuItem,
        menuItemType: MenuItemType | null
      ): MenuItem => {
        return {
          ...menuItem,
          type: menuItemType,
          link: undefined,
          dashboard: undefined,
          dashboardId: undefined
        }
      },
      getErrors: (menuItemErrorsObject?: MenuItemErrorsObject) => {
        return menuItemErrorsObject?.attributes?.type
      },
      getOptionId: (menuItemType: MenuItemType) => menuItemType.valueOf(),
      getOptionText: (menuItemType: MenuItemType) => this.detectMenuItemTypeDisplayName(menuItemType)
    })
  }

  private createBlockMenuItemLinkUrlFormField() {
    return new StringFormField<MenuItem, MenuItemErrorsObject>({
      getVisible: (menuItem: MenuItem) => menuItem.type === MenuItemType.LINK,
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().url(),
      getValue: (menuItem: MenuItem) => menuItem?.link?.url,
      setValue: (menuItem: MenuItem, url: string): MenuItem => {
        return {
          ...menuItem,
          link: isBlank(url) ? null : {
            ...menuItem.link,
            url,
            type: LinkType.BROWSER_LINK
          }
        }
      },
      getErrors: (menuItemErrorsObject?: MenuItemErrorsObject) => menuItemErrorsObject?.attributes?.link
    })
  }

  private createBlockMenuItemDashboardFormField() {
    return new SingleSelectFormField<MenuItem, MenuItemErrorsObject, Dashboard>({
      getVisible: (menuItem: MenuItem) => menuItem.type === MenuItemType.DASHBOARD,
      getObjects: async({
        query,
        lastObject
      }) => {
        return await this.getDashboardsUseCase.call({
          filter: { query },
          pagination: { id: lastObject?.id ?? undefined }
        })
      },
      getTitle: () => this.coreI18n.getTextProvider().dashboard(),
      getValue: (menuItem: MenuItem) => menuItem.dashboard,
      setValue: (menuItem: MenuItem, dashboard: Dashboard | null): MenuItem => {
        return {
          ...menuItem,
          dashboard: dashboard,
          dashboardId: dashboard && dashboard.id
        }
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getErrors: (menuItemErrorsObject?: MenuItemErrorsObject) => menuItemErrorsObject?.attributes?.dashboardId,
      getOptionId: (dashboard: Dashboard) => dashboard.id!.toString(),
      getOptionText: (dashboard: Dashboard) => dashboard.name
    })
  }

  private createBlockMenuItemProductsSetConditionsFormField() {
    return new ListFormField<MenuItem, MenuItemErrorsObject, ProductsSetCondition, ProductsSetErrorsObject>({
      getVisible: (menuItem: MenuItem) => menuItem.type === MenuItemType.PRODUCTS_SET,
      addObjectButtonName: this.coreI18n.getTextProvider().add(),
      getTitle: () => this.coreI18n.getTextProvider().productsSetConditions(),
      getValue: (menuItem: MenuItem): ProductsSetCondition[] | null | undefined => {
        return menuItem.productsSet && menuItem.productsSet.conditions
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getErrors: (menuItemErrorsObject?: MenuItemErrorsObject) => menuItemErrorsObject?.attributes?.productsSet,
      setValue: (menuItem: MenuItem, productsSetConditions: ProductsSetCondition[] | null): MenuItem => {
        return {
          ...menuItem,
          productsSet: {
            ...menuItem.productsSet ?? this.createProductsSet(),
            conditions: productsSetConditions
          }
        }
      },
      getNestedObjectId: (productsSetCondition: ProductsSetCondition) => productsSetCondition.clientId!,
      getNestedObjectTitle: (_: ProductsSetCondition, index: number) => {
        return this.coreI18n.getTextProvider().productsSetConditionWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (_: ProductsSetCondition, menuItemErrorsObject?: MenuItemErrorsObject) => {
        return menuItemErrorsObject?.productsSet
      },
      hasNestedErrorsObjects: (menuItemErrorsObject?: MenuItemErrorsObject) => {
        return isPresent(menuItemErrorsObject?.productsSet)
      },
      buildNewValue: (): ProductsSetCondition => this.createProductsSetCondition(),
      fields: this.createProductsSetConditionsFields().createFields()
    })
  }

  private setBlockTypeToBlock({
    block,
    blockType
  }: {
    readonly block: Block
    readonly blockType: BlockType | null
  }): Block {
    let newBlock: Block = {
      ...block,
      type: blockType,
      bannersViewType: undefined,
      productCategoriesViewType: undefined,
      productsViewType: undefined,
      menuItems: undefined
    }

    if (this.isHeaderVisible(blockType)) {
      newBlock = {
        ...newBlock,
        header: newBlock.header ?? this.createHeader()
      }
    } else if (this.isProductsSetVisible(blockType)) {
      newBlock = {
        ...newBlock,
        productsSet: newBlock.productsSet ?? this.createProductsSet()
      }
    }

    return newBlock
  }

  private createHeader(): Header {
    return {
      leftContentType: undefined,
      rightContentType: undefined,
      centerContentType: undefined
    }
  }

  private createDashboard(): Dashboard {
    return {
      id: undefined,
      name: undefined,
      blocks: undefined,
      code: undefined
    }
  }

  private createProductsSet(): ProductsSet {
    return {
      id: undefined,
      conditions: undefined,
      clientId: uuidv4()
    }
  }

  private createProductsSetCondition(): ProductsSetCondition {
    return {
      clientId: uuidv4()
    }
  }

  private detectBlockTypeDisplayName(blockType: BlockType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (blockType) {
      case BlockType.BANNERS:
        return coreTextProvider.blockBanners()
      case BlockType.SEARCH:
        return coreTextProvider.blockSearch()
      case BlockType.PRODUCT_CATEGORIES:
        return coreTextProvider.blockProductCategories()
      case BlockType.PRODUCTS:
        return coreTextProvider.blockProducts()
      case BlockType.LOYALTY_PROGRAM:
        return coreTextProvider.blockLoyaltyProgram()
      case BlockType.HEADER:
        return coreTextProvider.blockHeader()
      case BlockType.CURRENT_ORDERS:
        return coreTextProvider.blockCurrentOrders()
      case BlockType.MENU:
        return coreTextProvider.blockMenu()
      default:
        assertNever(blockType)
    }
  }

  private detectBannersBlockViewTypeDisplayName(blockViewType: BannersBlockViewType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (blockViewType) {
      case BannersBlockViewType.BANNERS:
        return coreTextProvider.blockViewTypeList()
      case BannersBlockViewType.BANNERS_PAGER:
        return coreTextProvider.blockViewTypePager()
      case BannersBlockViewType.BANNERS_SLIDER:
        return coreTextProvider.blockViewTypeSlider()
      default:
        assertNever(blockViewType)
    }
  }

  private detectProductsBlockViewTypeDisplayName(blockViewType: ProductsBlockViewType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (blockViewType) {
      case ProductsBlockViewType.PRODUCTS:
        return coreTextProvider.blockViewTypeList()
      case ProductsBlockViewType.PRODUCTS_SLIDER:
        return coreTextProvider.blockViewTypeSlider()
      default:
        assertNever(blockViewType)
    }
  }

  private detectProductCategoriesBlockViewTypeDisplayName(blockViewType: ProductCategoriesBlockViewType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (blockViewType) {
      case ProductCategoriesBlockViewType.PRODUCT_CATEGORIES:
        return coreTextProvider.blockViewTypeList()
      case ProductCategoriesBlockViewType.PRODUCT_CATEGORIES_SLIDER:
        return coreTextProvider.blockViewTypeSlider()
      default:
        assertNever(blockViewType)
    }
  }

  private detectHeaderContentTypeDisplayName(headerContentType: HeaderContentType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (headerContentType) {
      case HeaderContentType.LOGO:
        return coreTextProvider.logo()
      case HeaderContentType.PLACE:
        return coreTextProvider.place()
      default:
        assertNever(headerContentType)
    }
  }

  private detectMenuItemTypeDisplayName(menuItemType: MenuItemType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (menuItemType) {
      case MenuItemType.DASHBOARD:
        return coreTextProvider.dashboard()
      case MenuItemType.LINK:
        return coreTextProvider.link()
      case MenuItemType.PRODUCTS_SET:
        return coreTextProvider.productsSetConditions()
      default:
        assertNever(menuItemType)
    }
  }

  private isBlockNameVisible(blockType: BlockType | null | undefined): boolean {
    switch (blockType) {
      case BlockType.SEARCH:
      case BlockType.LOYALTY_PROGRAM:
      case BlockType.HEADER:
      case BlockType.CURRENT_ORDERS:
      case null:
      case undefined:
        return false
      default: {
        return true
      }
    }
  }

  private isAddBannerImageVisible(blockType: BlockType | null | undefined): boolean {
    switch (blockType) {
      case BlockType.BANNERS:
        return true
      default: {
        return false
      }
    }
  }

  private isSelectColumnsCountVisible(block: Block): boolean {
    return block.productCategoriesViewType === ProductCategoriesBlockViewType.PRODUCT_CATEGORIES ||
      block.bannersViewType === BannersBlockViewType.BANNERS
  }

  private isAddBlockCategoriesVisible(blockType: BlockType | null | undefined): boolean {
    switch (blockType) {
      case BlockType.PRODUCT_CATEGORIES:
        return true
      default: {
        return false
      }
    }
  }

  private isProductsSetVisible(blockType: BlockType | null | undefined): boolean {
    switch (blockType) {
      case BlockType.PRODUCTS:
        return true
      default: {
        return false
      }
    }
  }

  private isMenuVisible(blockType: BlockType | null | undefined): boolean {
    switch (blockType) {
      case BlockType.MENU:
        return true
      default: {
        return false
      }
    }
  }

  private isHeaderVisible(blockType: BlockType | null | undefined): boolean {
    switch (blockType) {
      case BlockType.HEADER:
        return true
      default: {
        return false
      }
    }
  }

  private findProductsSetForCondition({
    productsSetCondition
  }: {
    readonly productsSetCondition: ProductsSetCondition
  }): ProductsSet | undefined {
    const dashboard: Dashboard | undefined = this.objectPresentationLogic.getObject()

    if (isBlank(dashboard) || isBlank(dashboard.blocks)) {
      return undefined
    }

    for (const block of dashboard.blocks) {
      for (const searchingProductsSetCondition of block.productsSet?.conditions ?? []) {
        if (searchingProductsSetCondition.clientId === productsSetCondition.clientId) {
          return block.productsSet!
        }
      }

      for (const banner of block.banners ?? []) {
        for (const searchingProductsSetCondition of banner.productsSet?.conditions ?? []) {
          if (searchingProductsSetCondition.clientId === productsSetCondition.clientId) {
            return banner.productsSet!
          }
        }
      }

      for (const menuItem of block.menuItems ?? []) {
        for (const searchingProductsSetCondition of menuItem.productsSet?.conditions ?? []) {
          if (searchingProductsSetCondition.clientId === productsSetCondition.clientId) {
            return menuItem.productsSet!
          }
        }
      }
    }

    return undefined
  }

  private detectBannerDataTypeDisplayName(bannerDataType: BannerDataType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (bannerDataType) {
      case BannerDataType.PRODUCTS_SET:
        return coreTextProvider.productsSetConditions()
      case BannerDataType.LINK:
        return coreTextProvider.url()
      case BannerDataType.DASHBOARD:
        return coreTextProvider.dashboard()
      default:
        assertNever(bannerDataType)
    }
  }

  private detectImageVariantByBannerViewType(image: Image) {
    const viewType = this.blockByImages.get(image.clientId!) as BannersBlockViewType | undefined

    if (isBlank(viewType)) return image?.listImageVariant

    switch (viewType) {
      case BannersBlockViewType.BANNERS:
        return image?.listImageVariant
      case BannersBlockViewType.BANNERS_PAGER:
        return image?.pagerImageVariant
      case BannersBlockViewType.BANNERS_SLIDER:
        return image?.sliderImageVariant
      default:
        assertNever(viewType)
    }
  }
}
