import NetworkOrderPaymentMethod from "../entities/order-payment-methods/NetworkOrderPaymentMethod"
import OrderPaymentMethod from "../../domain/entities/order-payment-methods/OrderPaymentMethod"

export default class OrderPaymentMethodsMapper {
  mapNetworkToDomain({
    orderPaymentMethod
  }: {
    readonly orderPaymentMethod: NetworkOrderPaymentMethod
  }): OrderPaymentMethod {
    return {
      id: orderPaymentMethod.id,
      name: orderPaymentMethod.name,
      type: orderPaymentMethod.type
    }
  }

  mapDomainToNetwork({
    orderPaymentMethod
  }: {
    readonly orderPaymentMethod: OrderPaymentMethod
  }): NetworkOrderPaymentMethod {
    return new NetworkOrderPaymentMethod({
      id: orderPaymentMethod.id,
      name: orderPaymentMethod.name,
      type: orderPaymentMethod.type
    })
  }
}
