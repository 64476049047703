import { Expose } from "class-transformer"
import OrderPaymentMethodType from "../../../domain/entities/order-payment-methods/OrderPaymentMethodType"

export default class NetworkOrderPaymentMethod {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly type: OrderPaymentMethodType | null | undefined

  @Expose()
  readonly name: string | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly type: OrderPaymentMethodType | null | undefined
    readonly name: string | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.type = parameters.type
    this.name = parameters.name
  }
}
