import EcommerceDi from "../di/EcommerceDi"
import { createBrowserRouter } from "react-router-dom"
import CoreUrlProvider from "../core/presentation/services/CoreUrlProvider"
import UiMenuItem from "../../sqadmin/core/presentation/entities/UiMenuItem"
import CoreTextProvider from "../core/i18n/CoreTextProvider"
import createAuthenticationRoutes from "../../sqadmin/features/authentication/routing/createAuthenticationRoutes"
import createRootRoute from "../../sqadmin/features/root/routing/createRootRoute"
import createAdminsRoutes from "../features/admins/routing/createAdminsRoutes"
import createBonusProgramLevelsRoutes from "../features/bonus-program-levels/routing/createBonusProgramLevelsRoutes"
import createClientsRoutes from "../features/clients/routing/createClientsRoutes"
import createDashboardsRoutes from "../features/dashboards/routing/createDashboardsRoutes"
import createOptionsRoutes from "../features/options/routing/createOptionsRoutes"
import createOrderProcessingStatusesRoutes
  from "../features/order-processing-statuses/routing/createOrderProcessingStatusesRoutes"
import createOrdersRoutes from "../features/orders/routing/createOrdersRoutes"
import createPaymentStatusesRoutes from "../features/payment-statuses/routing/createPaymentStatusesRoutes"
import createPlacesRoutes from "../features/places/routing/createPlacesRoutes"
import createProductCategoriesRoutes from "../features/product-categories/routing/createProductCategoriesRoutes"
import createProductsRoutes from "../features/products/routing/createProductsRoutes"
import createPropertiesRoutes from "../features/properties/routing/createPropertiesRoutes"
import createDiscountRulesRoutes from "../features/discount-rules/routing/createDiscountRulesRoutes"
import createOrderReceivingMethodsRoutes
  from "../features/order-receiving-methods/routing/createOrderReceivingMethodsRoutes"
import createBonusPointsBurningRulesRoutes
  from "../features/bonus-points-burning-rules/routing/createBonusPointsBurningRulesRoutes"

export default function createEcommerceBrowserRouter({
  di
}: {
  readonly di: EcommerceDi
}) {
  const coreTextProvider = di.sqCoreCoreDiComponent.coreI18nDiModule
    .provideCoreI18n().getTextProvider()

  const menuItems = createRootPageMenuItems({
    coreTextProvider
  })

  const childrenRoutes = [
    ...createAdminsRoutes({ di }),
    ...createBonusProgramLevelsRoutes({ di }),
    ...createClientsRoutes({ di }),
    ...createDashboardsRoutes({ di }),
    ...createOptionsRoutes({ di }),
    ...createOrderProcessingStatusesRoutes({ di }),
    ...createOrdersRoutes({ di }),
    ...createPaymentStatusesRoutes({ di }),
    ...createPlacesRoutes({ di }),
    ...createProductCategoriesRoutes({ di }),
    ...createProductsRoutes({ di }),
    ...createPropertiesRoutes({ di }),
    ...createDiscountRulesRoutes({ di }),
    ...createOrderReceivingMethodsRoutes({ di }),
    ...createBonusPointsBurningRulesRoutes({ di })
  ]

  return createBrowserRouter([
    ...createAuthenticationRoutes({ di }),
    createRootRoute({
      di,
      menuItems,
      childrenRoutes
    })
  ])
}

function createRootPageMenuItems({
  coreTextProvider
}: {
  readonly coreTextProvider: CoreTextProvider
}): UiMenuItem[] {
  const coreUrlProvider = new CoreUrlProvider()
  return [
    {
      url: coreUrlProvider.buildOrdersUrl(),
      title: coreTextProvider.orders()
    },
    {
      url: coreUrlProvider.buildDashboardsUrl(),
      title: coreTextProvider.dashboards()
    },
    {
      url: coreUrlProvider.buildProductsUrl(),
      title: coreTextProvider.products()
    },
    {
      url: coreUrlProvider.buildProductCategoriesUrl(),
      title: coreTextProvider.productCategories()
    },
    {
      url: coreUrlProvider.buildPropertiesUrl(),
      title: coreTextProvider.properties()
    },
    {
      url: coreUrlProvider.buildOptionsUrl(),
      title: coreTextProvider.options()
    },
    {
      url: coreUrlProvider.buildPlacesUrl(),
      title: coreTextProvider.places()
    },
    {
      url: coreUrlProvider.buildOrderProcessingStatusesUrl(),
      title: coreTextProvider.orderProcessingStatuses()
    },
    {
      url: coreUrlProvider.buildOrderReceivingMethodsUrl(),
      title: coreTextProvider.orderReceivingMethods()
    },
    {
      url: coreUrlProvider.buildPaymentStatusesUrl(),
      title: coreTextProvider.paymentStatuses()
    },
    {
      url: coreUrlProvider.buildDiscountRulesUrl(),
      title: coreTextProvider.discountRules()
    },
    {
      url: coreUrlProvider.buildBonusProgramLevelsUrl(),
      title: coreTextProvider.bonusProgramLevels()
    },
    {
      url: coreUrlProvider.buildBonusPointsBurningRuleUrl(),
      title: coreTextProvider.bonusPointsBurningRule()
    },
    {
      url: coreUrlProvider.buildClientsUrl(),
      title: coreTextProvider.clients()
    },
    {
      url: coreUrlProvider.buildAdminsUrl(),
      title: coreTextProvider.admins()
    }
  ]
}
